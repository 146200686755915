import React from "react";
import NavigationFrame from "../../components/nav_frame";


class Customers extends React.Component {
    
    render() {
        return (
            <NavigationFrame>

            </NavigationFrame>
        )
    }
}

export default Customers;